import { userRef, contactRef } from '@/firebase/db'
export default {
  data: () => ({}),
  methods: {
    async getUserList({ orderBy, start, end, itemsPerPage = 10 }) {
      let result = userRef.orderBy(orderBy)
      if (itemsPerPage != -1) {
        result = result.limit(itemsPerPage)
      }
      if (start) {
        result = result.startAfter(start)
      }
      if (end) {
        result = result.endBefore(end).limitToLast(itemsPerPage)
      }
      result = await result.get()
      return result
    },
    async getFilterUser(email) {
      return await userRef.where('email', '==', email).get()
    },
    async getUserDetail(uid) {
      let user = await userRef.doc(uid).get()
      if (user.exists) {
        return user.data()
      } else {
        return null
      }
    },
    async getMessages({ unseen, seen }) {
      let data = contactRef

      if (seen) {
        data = data.where('seen', '==', true)
      } else if (unseen) {
        data = data.where('seen', '==', false)
      }

      data = await data.get()
      let res = []
      if (data.empty) {
        return []
      }
      data.forEach(e => {
        res.push({ ...e.data(), id: e.id })
      })
      return res
    },
    async changeMessageStatus(msg, val) {
      let newData = { ...msg }
      let id = newData.id
      delete newData.id
      newData.seen = val
      await contactRef.doc(id).set(newData)
    }
  }
}
