<template>
  <div>
    <v-dialog v-model="dialogs.delete" max-width="500">
      <v-card class="mx-auto">
        <v-card-text class="pt-4">
          <p>Are you sure? Do you want to delete this user?</p>
          <div class="my-3">Email: {{ toDelete.email }}</div>
          <v-fade-transition>
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
          </v-fade-transition>
          <div class="text-center">
            <v-btn depressed color="error" @click="deleteUser()">Yes</v-btn>
            <span @click="dialogs.delete = false">
              <cancel-button></cancel-button>
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="dialogs.login">
      <v-card>
        <v-card-title>
          Login as {{ login.user.email }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.login = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <p>
            Open this link in incognito window to login as
            {{ login.user.email }}
          </p>
          <a :href="loginURL()">{{ loginURL().substring(0, 100) }}+...</a>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title
        >Users <v-spacer></v-spacer>
        <input type="submit" hidden />
        <v-text-field
          v-model="email"
          label="Search"
          single-line
          hide-details
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-fade-transition>
          <v-alert v-if="success" type="success">{{ success }}</v-alert>
        </v-fade-transition>
        <base-data-table
          :search="email"
          :items="userList"
          :headers="headers"
          dense
        >
          <template v-slot:[`item.last_name`]="{ item }">
            <v-tooltip bottom open>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.last_name }}</span>
              </template>
              <span>{{ item.uid }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <router-link
              :to="`/admin/user/${item.uid}`"
              class="decoration-none"
              style="font-size: 0.75rem"
            >
              {{ item.email }}</router-link
            >
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <date-hover
              bottom
              :date="item.created_at"
              :withoutSuffix="true"
            ></date-hover>
          </template>
          <template v-slot:[`item.last_login`]="{ item }">
            <date-hover
              bottom
              :date="item.last_login"
              :withoutSuffix="true"
            ></date-hover>
          </template>

          <template v-slot:[`item.metrics.parcels`]="{ item }">
            {{ getMetric(item, 'parcels') }}
          </template>
          <template v-slot:[`item.metrics.taxes`]="{ item }">
            {{ getMetric(item, 'taxes') }}
          </template>
          <template v-slot:[`item.metrics.water_tests`]="{ item }">
            {{ getMetric(item, 'water_tests') }}
          </template>
          <template v-slot:[`item.metrics.septic_systems`]="{ item }">
            {{ getMetric(item, 'septic_systems') }}
          </template>
          <template v-slot:[`item.metrics.hvac`]="{ item }">
            {{ getMetric(item, 'hvac') }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="loginAsUser(item)" color="primary" v-on="on"
                  >mdi-login</v-icon
                >
              </template>
              <span>Log In</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="showDeleteUser(item)" color="error" v-on="on"
                  >mdi-trash-can</v-icon
                >
              </template>
              <span>Delete User</span>
            </v-tooltip>
          </template>
        </base-data-table>
        <!-- <div class="d-flex justify-center">
        <v-btn
          :loading="loadings.prev"
          color="primary"
          rounded
          outlined
          @click="prevPage()"
          ><v-icon>mdi-chevron-left</v-icon>Prev
        </v-btn>
        <v-btn
          :loading="loadings.next"
          color="primary"
          class="ml-2"
          outlined
          rounded
          @click="nextPage()"
          >Next <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import admin from '@/mixins/admin'
import utils from '@/mixins/utils'
import user from '@/mixins/user'
import parcel from '@/mixins/parcel'
import { mapGetters } from 'vuex'
import config from '../../config'
const api = config.api()
export default {
  mixins: [admin, utils, user, parcel],
  data: () => ({
    headers: [
      {
        text: 'Last Name',
        value: 'last_name'
      },
      {
        text: 'First Name',
        value: 'first_name'
      },
      {
        text: 'Signup',
        value: 'created_at'
      },
      {
        text: 'Login',
        value: 'last_login'
      },
      {
        text: 'Parcels',
        value: 'metrics.parcels',
        align: 'center'
      },
      {
        text: 'Water',
        value: 'metrics.water_tests',
        align: 'center'
      },
      {
        text: 'Septic ',
        value: 'metrics.septic_systems',
        align: 'center'
      },
      {
        text: 'HVAC',
        value: 'metrics.hvac',
        align: 'center'
      },
      {
        text: 'Solar',
        value: 'metrics.solar',
        align: 'center'
      },
      {
        text: 'Taxes',
        value: 'metrics.taxes',
        align: 'center'
      },
      {
        text: 'email',
        value: 'email'
      },
      {
        text: 'Action',
        value: 'action'
      }
    ],
    email: '',
    start: '',
    end: '',
    orderBy: 'email',
    loadings: { prev: false, next: false },
    timeout: null,
    dialogs: {
      delete: false,
      login: false
    },
    toDelete: '',
    success: '',
    error: '',
    count: { parcel: {}, taxes: {}, stpeic: {}, water_test: {} },
    login: {
      token: null,
      user: {}
    }
  }),
  async mounted() {
    this.updateUsers()
  },
  computed: {
    ...mapGetters({
      userList: 'admin/userList'
    }),
    firstEmail() {
      return { ...this.userList[0] }
    },
    lastEmail() {
      return { ...this.userList[this.userList.length - 1] }
    }
  },
  methods: {
    async updateUsers() {
      let ul = await this.getUserList({
        orderBy: this.orderBy,
        // start: this.start,
        // end: this.end,
        // itemsPerPage: this.itemsPerPage,
        itemsPerPage: -1
      })
      let userList = []
      ul.forEach(e => {
        userList.push({ ...e.data(), uid: e.id })
      })
      this.$store.commit('admin/userList', userList)
      // this.curPage = this.page;
    },
    async filterUser() {
      if (!this.email) {
        this.updateUsers()
        return
      }
      let u = await this.getFilterUser(this.email)
      if (!u.empty) {
        this.$store.commit('admin/userList', [
          { ...u.docs[0].data(), uid: u.docs[0].id }
        ])
      }
    },
    resetData() {
      this.email = ''
      this.updateUsers()
    },
    async nextPage() {
      this.loadings.next = true
      this.start = this.lastEmail.email
      this.end = ''
      await this.updateUsers()
      this.loadings.next = false
    },
    async prevPage() {
      this.loadings.prev = true
      this.end = this.firstEmail.email
      this.start = ''
      await this.updateUsers()
      this.loadings.prev = false
    },
    async filterEmail() {
      if (!this.email) {
        await this.updateUsers()
        return
      }
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.filterUser()
      }, 500)
    },
    showDeleteUser(user) {
      this.success = ''
      this.error = ''
      this.toDelete = user
      this.dialogs.delete = true
    },
    async deleteUser() {
      this.success = ''
      this.error = ''
      const url = `${api.cloudFunctionAPI}/admin/user?uid=${this.toDelete.uid}`
      this.$axios
        .delete(url)
        .then(async res => {
          this.success = res.data.message
          this.dialogs.delete = false
          await this.deleteUserDoc(this.toDelete.uid)
          this.updateUsers()
          setTimeout(() => {
            this.success = ''
          }, 3000)
        })
        .catch(err => {
          if (err.response && err.response.data.message) {
            this.error = err.response.data.message
          } else {
            this.error = 'Something went wrong'
          }
        })
    },
    getMetric(item, metric) {
      if (item.metrics) {
        if (item.metrics[metric]) {
          return item.metrics[metric]
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    loginURL() {
      return `${window.location.origin}/login?token=${this.login.token}`
    },
    loginAsUser(user) {
      const url = `${api.cloudFunctionAPI}/admin/token?uid=${user.uid}`
      this.$axios.get(url).then(res => {
        this.login.token = res.data.token
        this.login.user = user
        this.dialogs.login = true
      })
    }
  }
}
</script>

<style scoped>
.search-btn {
  margin-top: 2px !important;
  margin-left: 2px;
}
.search-bar {
  max-width: 500px;
}
</style>
